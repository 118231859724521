.form {
	&-subscribe {
		position: relative;
	}

	&-control {
		background: getColor('white.base');
		padding: getSpacing('4x') 132px getSpacing('4x') getSpacing('6x');
		font-size: 1rem;
		color: getColor('text.input');
		border: none;
		border-radius: 8px;
		height: 51px;
		width: 100%;

		&:focus-visible,
		&:focus-within {
			border: none;
			outline: none;
		}
	}

	&-submit {
		background: getColor('primary.base');
		color: getColor('black.base');
		padding: getSpacing('4x') getSpacing('6x');
		font-size: rem(18);
		font-weight: 700;
		border-radius: 8px;
		border: none;
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		cursor: pointer;

		&:hover {
			background: darken(getColor('primary.base'), 10%);
		}
	}
}
