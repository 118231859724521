.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(getColor('black.base'), 0.9);
	z-index: 1;
	width: 0;
	&.is-active {
		width: 100%;
	}
}
