.nav {
	@include mqmax('lg') {
		position: fixed;
		width: 300px;
		height: 100vh;
		background-color: $primary;
		overflow-x: hidden;
		transition: right 0.3s;
		top: 0;
		right: -300px;
		z-index: 9;
	}

	@include e('item') {
		list-style: none;
	}

	@include e('link') {
		color: getColor('black.base');

		&:hover,
		&.active {
			font-weight: 700;
		}
	}

	&.is-active {
		right: 0;
	}
}

.menu-toggle {
	display: none;
	flex-direction: column;
	cursor: pointer;
	background: none;
	border: none;

	@include mqmax('lg') {
		display: flex;
	}
}

.bar {
	width: 25px;
	height: 3px;
	background-color: getColor('black.base');
	margin: 3px 0;
}

.menu-close {
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	display: inline-block;
	width: 30px;
	height: 30px;
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 2;

	.bar {
		width: 100%;
		height: 3px;
		background-color: getColor('black.base');
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);

		&:first-child {
			transform: rotate(45deg);
		}

		&:last-child {
			transform: rotate(-45deg);
		}
	}
}