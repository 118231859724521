.card {
	display: flex;
	gap: getSpacing('6x');
	background: getColor('grey.20');
	padding: getSpacing('6x');
	border-radius: 8px;
	line-height: normal;
	height: 100%;

	@include m(bg-yellow) {
		background: #FFF8E0;
		box-shadow: 0px 4px 4px 0px rgba(255, 228, 111, 0.25);
	}

	@include e(title) {
		color: getColor('black.base');
	}

	@include e(text) {
		color: getColor('black.base');
		font-weight: 300;
	}

	@include mq('md') {
		display: block;
	}
}

.participation-card {
	background-color: $black;
	color: $white;
}

.stat-card {
	background-color: #151712;
	color: $primary;
}

.stat-cards .col-6 {
	flex: 0 0 50%;
}

.checker-card {
	color: $black;
	background: #FFFFFF33;
}

@media (min-width: 768px) {
	.stat-cards {
		flex-direction: column;
	}

	.stat-cards .col-6 {
		flex: 0 0 100%;
	}
}