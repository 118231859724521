.accordion {
	@include mq('lg') {
		max-width: 10 00px;
	}

	.accordion-content {
		overflow: hidden;
		padding: 0 getSpacing('4x');
		border-bottom: 1px solid getColor('primary.base');

		header {
			padding-top: getSpacing('6x');
			padding-bottom: getSpacing('4x');

			cursor: pointer;
		}

		&:hover {
			background-color: rgba(158, 232, 63, 0.05);
		}

		&.is-open {
			padding-bottom: 10px;
			background-color: rgba(158, 232, 63, 0.05);

			.icon {
				color: getColor('primary.base');
				border-color: getColor('primary.base');
			}

			.accordion-content-title {
				color: $primary;
			}
		}

		&-title {
			font-size: rem(24);
			font-weight: 500;
		}

		&-description {
			height: 0;
			font-size: 1.25rem;
			transition: height 0.4s ease;
		}

		&:first-child {
			border-top: 1px solid getColor('primary.base');
		}
	}

	.icon {
		font-size: rem(14);
		width: 24px;
		height: 24px;
		min-width: 24px;
		border-radius: 50%;
		border: 2px solid #737373;
		color: #737373;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}