.antenna{
    
    height: 4px;
    background-color: getColor('primary.light');
    position: relative;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;


    svg{
        position: absolute;
        height:auto;
        bottom:-3px;
    }
}   