//Mailerlite Form Css
#mlb2-5983064.ml-form-embedContainer .ml-form-embedWrapper {
    background:unset !important;
}

#mlb2-5983064.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody, #mlb2-5983064.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody {
    padding:0 !important;
}

#mlb2-5983064.ml-form-embedContainer .ml-form-align-center {
    text-align: left !important;
}

#mlb2-5983064.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody form {
    display: flex;
    align-items: center;
}

#mlb2-5983064.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedSubmit button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

#mlb2-5983064.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedSubmit {
    width: unset !important;
}

//Mailerlite Success Message
#mlb2-5983064.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody, #mlb2-5983064.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody {
//  background-color: getColor('yellow.20');
 padding:getSpacing('4x') !important;
 border-radius: 4px;
}

#mlb2-5983064.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent, #mlb2-5983064.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent h4, #mlb2-5983064.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-embedContent, #mlb2-5983064.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody .ml-form-successContent p {
    color:getColor('yellow.20') !important;
}

#mlb2-5983064.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody, #mlb2-5983064.ml-form-embedContainer .ml-form-embedWrapper .ml-form-successBody {
    padding-left:0 !important;
}