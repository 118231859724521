.header {
	&.sticky {
		position: sticky;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 99;
		background: $primary;
		padding: 12px !important;
		animation: slideIn 0.6s ease-in-out forwards;
	}

	@include e(banner_text) {
		margin-top: -2rem;

		@include mqmax('lg') {
			margin-top: 1rem;
		}

		@include mqmax('md') {
			margin-top: 1rem;
		}
	}
}

.logo {
	max-width: 175px;

	@include mq('md') {
		max-width: fit-content;
	}
}



@keyframes slideIn {
	0% {
		transform: translateY(-100px);
	}

	100% {
		transform: translateY(0);
	}
}

@media (max-width: 576px) {
	.sticky {
		position: fixed !important;
	}
}