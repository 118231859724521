// Base Color
$white: #ffffff !default;
$black: #000000 !default;

$primary: #9ee83f !default;

$buttonHover: #43700A !default;

$colorMap: (
	'white': ('base': $white,
	),
	'black': ('base': $black,
	),
	'primary': ('base': $primary,
		'light': #544406,
	),

	'grey': ('dark': #08050b,
		'base': #201d19,
		'light': #2b2722,
		'10':#4E473E,
		'20':#201D19,
	),

	'text': ('dark': #332904,
		'base': #d7d7d7,
		'input': #989898,
	),
	'yellow':('10':#FFF8E0,
		'20':#FFE46F,
		'30':#FDD330,
	)
);

// @@ This color map is used for Buttons and Backgrounds
$theme-colors: (
	'primary': $primary,
	'twitter':#1DA1F0,
	'facebook':#2878BD,
);