// Media query with min width
@mixin mq($mq-breakpoint, $mq-breakpoints: $grid-breakpoints) {
	@if map-has-key($mq-breakpoints, $mq-breakpoint) {
		$mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);
	}

	@if ($generate-responsive-classes) {
		@media screen and (min-width: #{$mq-breakpoint}) {
			@content;
		}
	}
}

// Media query with max width
@mixin mqmax($mq-breakpoint, $mq-breakpoints: $grid-breakpoints) {
	@if map-has-key($mq-breakpoints, $mq-breakpoint) {
		$mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint) - 1px;
	}

	@if ($generate-responsive-classes) {
		@media screen and (max-width: #{$mq-breakpoint}) {
			@content;
		}
	}
}

@media (min-width: 576px) {
	.responsive-checker {
		max-width: 700px;
		/* Sets a maximum width for the input */
		width: 100%;
		/* Ensures the input takes full width of its parent */
	}
}