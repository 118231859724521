.btn {
	border: none;
	font-size: rem(20);
	cursor: pointer;
	outline: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	border-radius: 8px;
	padding: 1rem 1.5rem;
	background-color: transparent;
	position: relative;
	transition: background 0.15s ease-in-out, color 0.15s ease-in-out;

	&.btn-primary {
		background: $black;
		color: $white;

		&:hover {
			background: $buttonHover;
		}
	}

	&.btn-check {
		color: $white;
		background: #151712;
		padding-left: 24px;
		border-radius: 0 8px 8px 0;

		&:hover {
			background: $buttonHover;
		}

		&:focus-visible {
			background: rgba(21, 23, 18, 0.10) !important;
		}
	}

	&.btn-link {
		background: transparent;
		color: getColor('black.base');

		&:hover {
			background: #86C931;
		}
	}

	&.btn-outlined {
		font-weight: 400;
		border: 1px solid transparent;

		&:hover {
			border-color: getColor('black.base');
		}
	}

	@each $theme-color, $color in $theme-colors {
		&-#{$theme-color} {
			background-color: $color;
			color: getColor('text.dark');

			&:hover {
				background: darken($color, 10%);
			}
		}
	}
}

.checker-input {
	outline: none;
	border: none;
	max-width: 600px;

	background: rgba(21, 23, 18, 0.20) !important;
	padding-left: 24px;
	border-radius: 8px 0 0 8px;

	&:hover {
		background: rgba(21, 23, 18, 0.13) !important;
	}

	&:focus-visible {
		background: rgba(21, 23, 18, 0.10) !important;
	}
}

.btn-facebook,
.btn-twitter {
	color: getColor('white.base');
}