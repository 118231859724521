figure {
	&.has-overlay {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba(getColor('primary.base'), 0.25);
		}
	}
}
