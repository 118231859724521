h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-ubuntu-mono;
	line-height: 1;
	color: getColor('primary.base');
}

h1 {
	@include getFontSize('h1');
}

h2 {
	@include getFontSize('h2');
	text-transform: uppercase;
}

h3 {
	@include getFontSize('h3');
}

h4 {
	@include getFontSize('h4');
}

h5 {
	@include getFontSize('h5');
}

h6 {
	@include getFontSize('h6');
}

.font-figtree {
	font-family: $font-figtree;
}

.font-inter {
	font-family: $font-inter;
}

.font-antonio {
	font-family: $font-antonio;
}

.font-chakra {
	font-family: $font-chakra-peth;
}

.font-racing {
	font-family: $font-racing;
}

.text-primary {
	color: $primary;
}

.fs-32 {
	font-size: 32px;
	line-height: 35.2px;
}

.fs-12 {
	font-size: 12px;
	line-height: 13.2px;
	font-weight: 400;
}