.banner {
	&:before {
		content: '';
		background-color: $primary;
		width: 100%;
		background-size: cover;
		height: 100vh;
		display: block;
		position: absolute;
		z-index: -1;
		top: 0;
	}

	@include m(img-fixed) {
		&::before {
			content: '';
			height: 100%;
			position: fixed;
		}
	}

	h1 {
		font-size: 70px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 16px;

		@include mqmax('xl') {
			flex-direction: column;
			gap: 4px;
			margin-bottom: 16px;
			align-items: flex-start;
		}

		@include mqmax('lg') {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 16px;
		}

		@include mqmax('sm') {
			flex-direction: column;
			margin-top: 16px;
		}

		span {
			font-family: $font-ubuntu-mono;
			font-size: 112px;
			font-style: normal;
			font-weight: 700;
			line-height: 120%;

			@include mq('md') {
				font-size: rem(200);
			}
		}
	}

	@media (max-width: 768px) {
		h1 {
			font-size: 32px;
		}
	}

	@include e('sub-title') {
		max-width: 500px;
	}

	@include e('buttons') {
		justify-content: center;

		@include mq('lg') {
			justify-content: start;
		}
	}
}