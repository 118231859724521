//Font Imports
//Provide url in '$font-url--google' variable from '_config.scss' partial
@if variable-exists(font-url--google) {
  @import url($font-url--google); // CSS font import from Google CDN
}

@if variable-exists(typefaces) {
  // @each $file, $option in $typefaces {
  //   @font-face {
  //     font-family: map-get(map-get($typefaces, $file), family);
  //     font-style: map-get(map-get($typefaces, $file), style);
  //     font-weight: map-get(map-get($typefaces, $file), weight);
  //     src: url("../../fonts/#{$file}.ttf") format("truetype"),
  //       url("../../../fonts/#{$file}.woff") format("woff"),
  //       url("../../../fonts/#{$file}.woff2") format("woff2");
  //   }
  // }
}
@font-face {
  font-family: 'Racing';
  src: url('../../../fonts/RacingRegular.woff2') format('woff2'),
      url('../../../fonts/RacingRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

